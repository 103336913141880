import router from '@/router'
// eslint-disable-next-line object-curly-newline
import {
    reactive,
    getCurrentInstance,
    watch,
    toRefs
} from '@vue/composition-api'
import moment from "moment"

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
    const {
        route: resolvedRoute
    } = router.resolve(route)
    return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
    const vm = getCurrentInstance().proxy
    const state = reactive({
        route: vm.$route,
    })

    watch(
        () => vm.$route,
        r => {
            state.route = r
        },
    )

    return {
        ...toRefs(state),
        router: vm.$router
    }
}


export const getMessageError = function (error) {
    let message = ""

    try {
        message = error.response.data.errors.email[0]
        console.error(message)
        return message
    } catch (err) { }

    try {
        message = error.response.data.data !== undefined ? error.response.data.data : error.response.data.message
        if (message !== '' && message !== undefined) {
            console.error(message)
            return message
        }
    } catch (err) { }

    try {
        message = error.message
        console.error(message)
        return message
    } catch (err) { }

    try {
        message = error.data.exception
        console.error(message)
        return message
    } catch (err) { }

    return message
}

export const convertTZ = function (date, tzString) {
    let data = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
        timeZone: tzString
    }));
    return data
}

export const getDiferenceTimeZones = function (tzStringCentral, tzStringPlace, placeTime, formatDate) {
    let res = "";
    try {
        let currentPlaceTimeZone = convertTZ("2012/04/20 10:00:00", tzStringPlace);
        let centralTimeZone = convertTZ("2012/04/20 10:00:00", tzStringCentral);
        currentPlaceTimeZone = moment(currentPlaceTimeZone);
        centralTimeZone = moment(centralTimeZone);


        //let diff = currentPlaceTimeZone.diff(centralTimeZone,'hours');
        let diff = centralTimeZone.diff(currentPlaceTimeZone, 'hours');
        placeTime = moment(placeTime);
        if (diff < 0) {
            let value = Math.abs(diff)
            placeTime = placeTime.subtract(value, 'hours');
        }
        if (diff > 0) {
            placeTime = placeTime.add((diff), 'hours');
        }

        res = placeTime.format(formatDate);
    } catch (error) {
        console.error(error);
    }

    return res;
}

export const getCentralTime = function (item, formatTime) {
    let res = ""
    if (item.time_zone) {
        let currentDate = moment(item.booking_date).format('MM-DD-YYYY');
        if (item.pickup_time == null) {
            return null;
        }
        currentDate = currentDate + " " + item.pickup_time;
        res = getDiferenceTimeZones('America/Chicago', item.time_zone, currentDate, formatTime)
    }
    return res
}


export const GetTotalExtraCharge = function (booking) {
    let res = 0;
    let listExtraCharges = booking.extra_charge;

    listExtraCharges.forEach(element => {

        let addAmount = false;
        if (element.status == null) {
            addAmount = true;
        }

        if (element.status != null) {
            if (element.status.toUpperCase() == "PRE-AUTHORIZED" || element.status == "" || element.status.toUpperCase() == "PAID") {
                addAmount = true;
            }
        }

        if (addAmount == true) {
            let amount = element.amount;

            if (amount == "" || amount == null) {
                amount = 0;
            }

            amount = parseFloat(amount);
            res = res + amount;
        }


    });

    return res;
}


export const GetInvoiceExtraChargeStatus = function (item) {

        let listExtraCharges = item.extra_charges;
        let finalStatus = 'PAID';

        if (item.payment_type == 'cc') {
            if(listExtraCharges.length>0)
            {
                finalStatus = 'CANCELLED';
            }else{
                finalStatus = "THERE IS NO EXTRA CHARGES";
            }
           
            listExtraCharges.forEach(element => {
                if ((element.status.toUpperCase() == 'PRE-AUTHORIZED')) {
                    finalStatus = 'PRE-AUTHORIZED';
                }
                if (element.status.toUpperCase() == 'PAID') {
                    finalStatus = 'PAID';
                }
            });
        }
        
        if (item.payment_type == 'il') {
            if(listExtraCharges.length>0)
            {
                finalStatus = 'PAID';
            }else{
                finalStatus = "THERE IS NO EXTRA CHARGES";
            }

            listExtraCharges.forEach(element => {  
                if ((element.status == '' || element.status == null )) {
                    finalStatus = '';
                }
            });
        }

        return finalStatus;
    }


/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
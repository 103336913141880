<template>
    <ListBillingReservation></ListBillingReservation>
</template>
  
  <script>

import ListBillingReservation from "@core/layouts/components/admin-components/ListBillingReservation.vue";

export default {
    name: "Reservations",

    components: {
      ListBillingReservation,
    }
}
</script>
  
  <style scoped>
  </style>